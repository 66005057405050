<template>
  <div id="app" class="Router">
    <div style="height: 3rem;"></div>
    <van-nav-bar
        class="nav-bar"
        :title="$route.meta.title"
        fixed
        :left-text="active === 'myTickets' ? '票券列表' : ''"
        @click-left="onClickLeft"
        @click-right="onClickRight"
        :border="false"
        style="background-color: #409EFF;"
    >
      <template #right>
<!--        <span style="color: white" v-if="active === 'list'">我的票券</span>-->
        <span style="color: white" v-if="active === 'myTickets'">切换账号</span>
      </template>
    </van-nav-bar>
    <transition :name="transitionName">
      <router-view :key="$route.fullPath"></router-view>
    </transition>
    <div style="height: 3rem;"></div>
    <van-tabbar v-model="active" route>
      <van-tabbar-item name="list" icon="apps-o" to="/list">票券列表</van-tabbar-item>
      <van-tabbar-item name="myTickets" icon="contact-o" to="/myTickets">我的票券</van-tabbar-item>
    </van-tabbar>

    <van-dialog v-model="show" title="提示" show-cancel-button :before-close="submit">
      <van-form validate-trigger="onBlur" ref="form">
        <van-field
            v-model="userPhone"
            name="手机号"
            label="手机号"
            :error-message="errors[0]"
            :error="errors.length > 0"
            placeholder="请输入手机号"
            :rules="[{ required: true, message: '请填写手机号' }, { pattern: /^1[3456789]\d{9}$/, message: '请填写正确的手机号' }]"
        />
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import {getTicket} from "@/utils/api";

export default {
  name: 'app',
  data(){
    return{
      active: 'list',
      transitionName:'',
      show: false,
      userPhone: '',
      errors: []
    }
  },
  watch: {//使用watch 监听$router的变化

    $route(to, from) {
      //如果to索引大于from索引,判断为前进状态,反之则为后退状态
      if(to.meta.index > from.meta.index) {
        //设置动画名称
        this.transitionName = 'slide-left';
      }else{
        this.transitionName = 'slide-right';
      }
    }
  },
  methods:{
    onClickLeft(){
      this.active = 'list'
      this.$router.push({path: '/list'})
    },
    onClickRight(){
      this.show = true
    },

    submit(action, done){
      if(action === 'confirm'){
        this.$refs.form.validate().then(
          res =>{
            done()
            window.sessionStorage.setItem('phone', this.userPhone)
            this.$router.push({ path: '/myTickets', query:{ phone: this.userPhone } })
            this.userPhone = ''
          }).catch(
            err =>{
              // 表单校验不通过，获取第一个错误信息
            //this.errors = Object.values(this.$refs.form.fields).find(field => field.validateState === 'error').validateMessage;
            done(false)
            }
          )
      }else{
        this.userPhone = ''
        done()
      }
    }
  }
}
</script>

<style>
#app{
  height: 100%;
}
body,html{
  padding: 0;
  margin: 0;
  height: 100%;
  box-sizing: border-box;
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  /*font-family: my-font;*/
  color: rgb(116, 116, 116);
}
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: all 500ms;
  position: absolute;
}

.slide-right-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.slide-left-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.Router{
  position: relative;
}
.Router>*{
  width: 100%;
}
.nav-bar .van-nav-bar__title{
  color: white;
}
.nav-bar .van-nav-bar__right .van-nav-bar__text{
  color: white;
}
.nav-bar .van-nav-bar__left .van-nav-bar__text{
  color: white;
}
</style>
