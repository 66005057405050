import { Request, setConfig } from 'se-axios'
import { Notify, Dialog, Toast  } from 'vant';
setConfig({
    isToken: false,
    
})
const request = new Request({
    requestConfig:{
        baseURL: 'https://cs.hszl-bd.cn/api',
        timeout: 15000,
        success:(e) =>{
            Notify({ type: 'success', message: '获取成功', duration: 1000 });
        },
        exception:(e) =>{
            Dialog.alert({
                title: '错误',
                message: e,
            }).then(() => {
                // on close
            });
        },
        warning:(e) =>{
            Dialog.alert({
                title: '警告',
                message: e,
            }).then(() => {
                // on close
            });
        },
        acquiesce: (e) =>{

            //Toast.fail(e);
        }
    },

})

export function getTicketList(params){
    return request({
        url: '/marketing/ticket/pc/list',
        method: 'get',
        params: params
    })
}

export function getTicket(data){
    return request({
        url: '/marketing/ticket/receive',
        method: 'post',
        data: data,
        success:(e)=>{
            Dialog.alert({
                title: '提示',
                message: '领取成功'
            }).then(

            )
        },
        acquiesce: (e) =>{

        }
    })
}

export function getMyTickets(params){
    return request({
        url: '/marketing/ticket/myTicket',
        method: 'get',
        params: params,
    })
}

export function getSms(data){
    return request({
        url: '/marketing/ticket/sendSms',
        method: 'post',
        data: data,
        success:(e) =>{
            Toast({
                message: '发送成功，请注意接收',
                position: 'top',
            });
        }
    })
}

export function getQuerySms(data){
    return request({
        url: '/marketing/ticket/querySendSms',
        method: 'post',
        data: data,
        success:(e) =>{
            Toast({
                message: '发送成功，请注意接收',
                position: 'top'
            })
        }
    })
}
